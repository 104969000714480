.sidebar {
  @apply flex flex-col w-full border-r border-gray-100 bg-gray-50;
  max-width: 280px;
}

.nav {
  @apply flex flex-col p-6 space-y-2 text-gray-600;

  & > a {
    @apply flex items-center px-3 py-2 text-sm font-medium tracking-wide rounded;

    &.active {
      @apply text-gray-800 bg-gray-200;
    }

    & > svg {
      @apply w-5 h-5 mr-2;
    }
  }
}

.quickfilter {
  @apply flex flex-col flex-1 py-6 space-y-3 px-9;

  & > li:nth-child(1) {
    @apply text-xs font-medium tracking-wide text-gray-400 uppercase;
  }

  & > li:not(:nth-child(1)) {
    @apply flex items-center text-sm tracking-wide text-gray-500 transition-colors hover:text-gray-600;

    &::before {
      content: "";
      @apply w-3 h-3 mr-2 rounded-full;
    }
  }

  & > li {
    &:nth-child(2)::before {
      @apply bg-red-400;
    }

    &:nth-child(3)::before {
      @apply bg-yellow-400;
    }

    &:nth-child(4)::before {
      @apply bg-indigo-400;
    }

    &:nth-child(5)::before {
      @apply bg-green-400;
    }
  }
}

.footer {
  @apply flex items-center justify-between p-6;

  & .user {
    @apply flex items-center flex-1 space-x-2;

    & > .avatar {
      @apply w-8 h-8 border-2 border-white rounded-full shadow;
    }

    & > .email {
      @apply block w-full text-sm font-medium text-gray-600 truncate;
    }
  }

  & .logout {
    @apply flex items-center flex-shrink-0 ml-2 text-gray-400 transition-colors hover:text-gray-500;

    & > svg {
      @apply w-5 h-5;
    }
  }
}
