@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply overscroll-y-none;
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply m-0 appearance-none;
  }

  input[type="checkbox"] {
    @apply text-indigo-600 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
  }
}

@layer components {
  .btn {
    @apply flex items-center px-3 py-2 space-x-1 text-sm font-medium text-gray-600 transition-opacity bg-gray-200 rounded outline-none hover:bg-gray-300;

    & > svg {
      @apply w-5 h-5;
    }
  }
}
